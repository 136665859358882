import React from 'react';
import './AboutUsPage.css'; 


const AboutUsPage = () => {

  return (
    <div className="about-us-container">
      <div style={{width:"40%"}}>
        <h2 className='heading'>About us</h2>
        <hr style={{color:"rgb(0, 255, 0)", marginTop:"3px"}}/>
        </div>
      <div className="about-us-content">
        <p>Welcome to our operations Links Forums!</p>
        <p>We are a clandestine organization dedicated to gathering and disseminating classified information.</p>
        <p>Our mission is to operate in the shadows, ensuring the security and stability of our world.</p>
        <p>Feel free to explore our encrypted archives and discover valuable intelligence.</p>
        <p>If you have any confidential inquiries or feedback, please don't hesitate to contact us through secure Email.</p>

        <h2 className="about-us-section">Our Agents</h2>
        <p>Meet the skilled operatives behind our covert operations:</p>
        <ul>
          <li>Agent X - Director of Espionage</li>
          <li>Agent Y - Master of Infiltration</li>
          <li>Agent Z - Cybersecurity Specialist</li>
        </ul>

        <h2 className="about-us-section">Our Mission</h2>
        <p>At our core, we believe in:</p>
        <ul>
          <li>Protecting national security interests</li>
          <li>Operating with utmost discretion and precision</li>
          <li>Adapting to ever-evolving threats</li>
        </ul>

        <h2 className="about-us-section">Join Us</h2>
        <p>We're always looking for talented individuals to join our elite team. Here's how you can become an agent:</p>
        <ul>
          <li>Submit your application: If you possess unique skills and a strong sense of duty, we want to hear from you.</li>
          <li>Undergo rigorous training: If selected, you'll undergo intensive training to become a certified agent.</li>
          <li>Embark on missions: Join our covert operations and make a difference in the world.</li>
        </ul>
      </div>
    </div>
  );
}

export default AboutUsPage;
