import React from 'react'


const Coding = () => {
  const codingWebsites = [
    { name: 'GitHub', url: 'https://github.com', purpose: 'Platform for Hosting and Collaborating on Git Repositories' },
    { name: 'Stack Overflow', url: 'https://stackoverflow.com', purpose: 'Q&A Community for Programmers and Developers' },
    { name: 'Codecademy', url: 'https://www.codecademy.com', purpose: 'Online Interactive Platform for Learning to Code' },
    { name: 'FreeCodeCamp', url: 'https://www.freecodecamp.org', purpose: 'Free Online Coding Bootcamp' },
    { name: 'W3Schools', url: 'https://www.w3schools.com', purpose: 'Web Development Tutorials and References' },
    { name: 'MDN Web Docs', url: 'https://developer.mozilla.org', purpose: 'Documentation for Web Technologies' },
    { name: 'LeetCode', url: 'https://leetcode.com', purpose: 'Platform for Coding Challenges and Interview Preparation' },
    { name: 'Hackerrank', url: 'https://www.hackerrank.com', purpose: 'Platform for Coding Challenges and Competitive Programming' },
    { name: 'GeeksforGeeks', url: 'https://www.geeksforgeeks.org', purpose: 'Computer Science Portal for Interview Preparation and Coding Practice' },
    { name: 'CodePen', url: 'https://codepen.io', purpose: 'Online Community for Testing and Showcasing HTML, CSS, and JavaScript Code Snippets' },
    { name: 'Dev.to', url: 'https://dev.to', purpose: 'Community and Blogging Platform for Developers' },
    { name: 'CSS-Tricks', url: 'https://css-tricks.com', purpose: 'Web Design and Development Blog with CSS Tips and Tricks' },
    { name: 'The Odin Project', url: 'https://www.theodinproject.com', purpose: 'Free Open Source Curriculum for Learning Web Development' },
    { name: 'HackerEarth', url: 'https://www.hackerearth.com', purpose: 'Platform for Coding Challenges, Hiring, and Technical Recruitment' },
    { name: 'Codewars', url: 'https://www.codewars.com', purpose: 'Platform for Training on Code Challenges and Kata' },
    { name: 'Kaggle', url: 'https://www.kaggle.com', purpose: 'Platform for Data Science and Machine Learning Competitions' },
    { name: 'TopCoder', url: 'https://www.topcoder.com', purpose: 'Platform for Competitive Programming and Software Development Challenges' },
    { name: 'Exercism', url: 'https://exercism.io', purpose: 'Platform for Practicing Programming Exercises in Multiple Languages' },
    { name: 'Project Euler', url: 'https://projecteuler.net', purpose: 'Platform for Mathematical and Computational Problem-Solving' },
    { name: 'Rosetta Code', url: 'https://rosettacode.org', purpose: 'Programming Chrestomathy Website with Solutions in Multiple Programming Languages' },
    { name: 'HackerRank Interview Preparation Kit', url: 'https://www.hackerrank.com/interview/interview-preparation-kit', purpose: 'Specific Section on HackerRank for Interview Preparation' },
    { name: 'TechCrunch', url: 'https://techcrunch.com', purpose: 'Technology News Website Covering Startups and Tech Trends' },
    { name: 'The New Stack', url: 'https://thenewstack.io', purpose: 'Technology News and Analysis Focusing on Software Development and DevOps' },
    { name: 'InfoQ', url: 'https://www.infoq.com', purpose: 'Online Community and Content Platform for Software Developers and Architects' },
    { name: 'Hacker News', url: 'https://news.ycombinator.com', purpose: 'Community-Driven Tech News Aggregator' },
    { name: 'Google Developers', url: 'https://developers.google.com', purpose: 'Official Google Developer Documentation and Resources' },
    { name: 'Mozilla Developer Network (MDN) Web Docs', url: 'https://developer.mozilla.org', purpose: 'Official Mozilla Documentation for Web Technologies' },
    { name: 'Microsoft Developer Network (MSDN)', url: 'https://msdn.microsoft.com', purpose: 'Official Microsoft Documentation and Resources for Developers' },
    { name: 'Coursera', url: 'https://www.coursera.org', purpose: 'Online Learning Platform Offering Courses in Programming and Computer Science' },
    { name: 'edX', url: 'https://www.edx.org', purpose: 'Online Learning Platform Offering Courses in Programming, Computer Science, and more' },
    { name: 'CodeSignal', url: 'https://codesignal.com', purpose: 'Platform for Practicing Coding Skills and Preparing for Technical Interviews' },
    { name: 'Coderbyte', url: 'https://coderbyte.com', purpose: 'Platform for Practicing Coding Challenges and Improving Problem-Solving Skills' },
    { name: 'CodeChef', url: 'https://www.codechef.com', purpose: 'Competitive Programming Platform and Coding Community' },
    { name: 'Sphere Online Judge (SPOJ)', url: 'https://www.spoj.com', purpose: 'Online Judge System and Platform for Programming Contests' },
    { name: 'AtCoder', url: 'https://atcoder.jp', purpose: 'Japanese Competitive Programming Platform Hosting Contests and Practice Sessions' },
    { name: 'UVa Online Judge', url: 'https://onlinejudge.org', purpose: 'Online Judge System for Practicing Competitive Programming Problems' },
    { name: 'LeetCode Explore', url: 'https://leetcode.com/explore', purpose: 'LeetCode Section for Exploring Different Topics and Practice Questions' },
    { name: 'Codeforces', url: 'https://codeforces.com', purpose: 'Competitive Programming Platform and Contest Hosting Website' },
    { name: 'HackerRank Practice', url: 'https://www.hackerrank.com/domains/tutorials/10-days-of-javascript', purpose: 'HackerRank Section for Practicing Coding Challenges and Tutorials' },
    { name: 'CodeCombat', url: 'https://codecombat.com', purpose: 'Platform for Learning Programming Through a Game-Based Environment' },
    { name: 'CheckiO', url: 'https://checkio.org', purpose: 'Platform for Learning Python and Solving Coding Challenges in a Game-Like Setting' },
    { name: 'Brilliant', url: 'https://brilliant.org', purpose: 'Platform for Learning Mathematics and Science Through Problem-Solving' },
    { name: 'Programming Praxis', url: 'https://programmingpraxis.com', purpose: 'Website Featuring Programming Exercises for Practice and Improvement' },
    { name: 'CodeAbbey', url: 'https://www.codeabbey.com', purpose: 'Platform for Practicing Coding Problems and Competing with Others' },
    { name: 'Project-Based Learning', url: 'https://github.com/tuvtran/project-based-learning', purpose: 'GitHub Repository Curating Project-Based Learning Resources' },
  ];
  
  return (
    <div className="boxcontainer">
      <ul className="listAll">
      <div style={{ width: "40%" }}>
          <h2 className="heading">List of coding</h2>
          <hr style={{ color: "rgb(0, 255, 0)", marginTop: "3px" }} />
        </div>
        {codingWebsites.map(website => (
          <li key={website.name} className="all-items">
            <span className="website-name">{website.name} - </span>
            <a href={website.url} className="website-link" target="_blank" rel="noopener noreferrer">
              {website.purpose}
            </a>
            <span className="verified">Verified</span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Coding
