import React from 'react'

const searchengine = () => {
  
  const searchEngines = [
    { name: 'Google', url: 'https://www.google.com', purpose: 'Search engine providing web search, images, videos, and more' },
    { name: 'Bing', url: 'https://www.bing.com', purpose: 'Search engine owned and operated by Microsoft' },
    { name: 'Brave Search', url: 'https://search.brave.com', purpose: 'Search engine provided by Brave, focused on privacy and user control' },
    { name: 'Yahoo', url: 'https://www.yahoo.com', purpose: 'Search engine offering web search, news, email, and more' },
    { name: 'DuckDuckGo', url: 'https://duckduckgo.com', purpose: 'Privacy-focused search engine that does not track user data' },
    { name: 'Baidu', url: 'https://www.baidu.com', purpose: 'Chinese search engine providing web search, maps, and more' },
    { name: 'Yandex', url: 'https://yandex.com', purpose: 'Russian search engine offering web search, maps, and more' },
    { name: 'Ask.com', url: 'https://www.ask.com', purpose: 'Question and answer based search engine' },
    { name: 'AOL Search', url: 'https://search.aol.com', purpose: 'Search engine provided by AOL offering web search, news, and more' },
    { name: 'Ecosia', url: 'https://www.ecosia.org', purpose: 'Search engine that uses ad revenue to plant trees' },
    { name: 'Startpage', url: 'https://www.startpage.com', purpose: 'Privacy-focused search engine that fetches results from Google without tracking' },
    { name: 'Qwant', url: 'https://www.qwant.com', purpose: 'European search engine that respects user privacy' },
    { name: 'Naver', url: 'https://www.naver.com', purpose: 'South Korean search engine offering web search, news, and more' },
    { name: 'Seznam', url: 'https://www.seznam.cz', purpose: 'Czech search engine providing web search, email, and more' },
    { name: 'Swisscows', url: 'https://swisscows.com', purpose: 'Privacy-focused search engine based in Switzerland' },
    { name: 'Ekoru', url: 'https://ekoru.org', purpose: 'Search engine that uses revenue to support ocean cleanup efforts' },
    { name: 'Gibiru', url: 'https://gibiru.com', purpose: 'Uncensored anonymous search engine' },
    { name: 'AdBlock Search', url: 'https://search.adblockplus.org', purpose: 'Search engine provided by AdBlock Plus with built-in ad blocking features' },
    { name: 'AdGuard Search', url: 'https://adguard.com/search.html', purpose: 'Search engine provided by AdGuard with integrated ad blocking capabilities' },
    
  ];
  
  return (
    <div className="boxcontainer">
      <ul className="listAll">
      <div style={{ width: "40%" }}>
          <h2 className="heading">List of Searchengine</h2>
          <hr style={{ color: "rgb(0, 255, 0)", marginTop: "3px" }} />
        </div>
        {searchEngines.map(website => (
          <li key={website.name} className="all-items">
            <span className="website-name">{website.name} - </span>
            <a href={website.url} className="website-link" target="_blank" rel="noopener noreferrer">
              {website.purpose}
            </a>
            <span className="verified">Verified</span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default searchengine
