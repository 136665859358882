import React from 'react'

const sport = () => {
  const sportsWebsites = [
    // Soccer/Football
    { name: 'FIFA', url: 'https://www.fifa.com', purpose: 'Official website of the Fédération Internationale de Football Association (FIFA)' },
    { name: 'UEFA', url: 'https://www.uefa.com', purpose: 'Official website of the Union of European Football Associations (UEFA)' },
    { name: 'Premier League', url: 'https://www.premierleague.com', purpose: 'Official website of the English Premier League' },
    { name: 'La Liga', url: 'https://www.laliga.com', purpose: 'Official website of La Liga, the top professional football division in Spain' },
    { name: 'Bundesliga', url: 'https://www.bundesliga.com', purpose: 'Official website of the Bundesliga, the top professional football league in Germany' },
    { name: 'Serie A', url: 'https://www.legaseriea.it', purpose: 'Official website of Serie A, the top professional football league in Italy' },
    // Basketball
    { name: 'NBA', url: 'https://www.nba.com', purpose: 'Official website of the National Basketball Association (NBA)' },
    { name: 'EuroLeague', url: 'https://www.euroleague.net', purpose: 'Official website of the EuroLeague, the top professional basketball club competition in Europe' },
    { name: 'FIBA', url: 'https://www.fiba.basketball', purpose: 'Official website of the International Basketball Federation (FIBA)' },
    // American Football
    { name: 'NFL', url: 'https://www.nfl.com', purpose: 'Official website of the National Football League (NFL)' },
    { name: 'ESPN NFL', url: 'https://www.espn.com/nfl', purpose: 'ESPN section dedicated to NFL coverage' },
    // Baseball
    { name: 'MLB', url: 'https://www.mlb.com', purpose: 'Official website of Major League Baseball (MLB)' },
    { name: 'ESPN MLB', url: 'https://www.espn.com/mlb', purpose: 'ESPN section dedicated to MLB coverage' },
    // Ice Hockey
    { name: 'NHL', url: 'https://www.nhl.com', purpose: 'Official website of the National Hockey League (NHL)' },
    { name: 'IIHF', url: 'https://www.iihf.com', purpose: 'Official website of the International Ice Hockey Federation (IIHF)' },
    // Tennis
    { name: 'ATP Tour', url: 'https://www.atptour.com', purpose: 'Official website of the Association of Tennis Professionals (ATP)' },
    { name: 'WTA Tour', url: 'https://www.wtatennis.com', purpose: 'Official website of the Women\'s Tennis Association (WTA)' },
    { name: 'Grand Slam Board', url: 'https://www.grandslamboard.org', purpose: 'Official website of the Grand Slam Board, overseeing the four major tennis tournaments' },
    // Golf
    { name: 'PGA Tour', url: 'https://www.pgatour.com', purpose: 'Official website of the Professional Golfers\' Association (PGA) Tour' },
    { name: 'European Tour', url: 'https://www.europeantour.com', purpose: 'Official website of the European Tour for professional golf' },
    { name: 'The Open', url: 'https://www.theopen.com', purpose: 'Official website of The Open Championship, one of golf\'s major championships' },
    // Motorsports
    { name: 'Formula 1', url: 'https://www.formula1.com', purpose: 'Official website of Formula 1, the highest class of international auto racing' },
    { name: 'MotoGP', url: 'https://www.motogp.com', purpose: 'Official website of MotoGP, the premier class of motorcycle road racing events' },
    { name: 'NASCAR', url: 'https://www.nascar.com', purpose: 'Official website of the National Association for Stock Car Auto Racing (NASCAR)' },
    // Rugby
    { name: 'World Rugby', url: 'https://www.world.rugby', purpose: 'Official website of World Rugby, the international governing body for rugby union' },
    { name: 'Six Nations Rugby', url: 'https://www.sixnationsrugby.com', purpose: 'Official website of the Six Nations Championship, an annual international rugby union competition' },
    { name: 'Rugby World Cup', url: 'https://www.rugbyworldcup.com', purpose: 'Official website of the Rugby World Cup, the premier international rugby union competition' },
    // Cricket
    { name: 'ICC', url: 'https://www.icc-cricket.com', purpose: 'Official website of the International Cricket Council (ICC)' },
    { name: 'ESPNcricinfo', url: 'https://www.espncricinfo.com', purpose: 'Leading cricket website providing news, scores, and statistics' },
    // Athletics
    { name: 'World Athletics', url: 'https://www.worldathletics.org', purpose: 'Official website of World Athletics, the international governing body for the sport of athletics' },
    // Boxing
    { name: 'BoxRec', url: 'https://boxrec.com', purpose: 'Boxing database and website for records and statistics' },
    // Mixed Martial Arts (MMA)
    { name: 'UFC', url: 'https://www.ufc.com', purpose: 'Official website of the Ultimate Fighting Championship (UFC)' },
    { name: 'MMA Fighting', url: 'https://www.mmafighting.com', purpose: 'Leading MMA news website covering events and fighters' }
  ];
  
  return (
    <div className="boxcontainer">
      <ul className="listAll">
      <div style={{ width: "40%" }}>
          <h2 className="heading">List of Sporty</h2>
          <hr style={{ color: "rgb(0, 255, 0)", marginTop: "3px" }} />
        </div>
        {sportsWebsites.map(website => (
          <li key={website.name} className="all-items">
            <span className="website-name">{website.name} - </span>
            <a href={website.url} className="website-link" target="_blank" rel="noopener noreferrer">
              {website.purpose}
            </a>
            <span className="verified">Verified</span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default sport
