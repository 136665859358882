import React from 'react'

const hackingcourse = () => {
    const HackrMediaPlatfrms = [


        { name: 'TryHackMe', url: 'https://www.tryhackme.com', purpose: 'Online platform providing virtual labs and challenges for learning cybersecurity skills', },
        { name: 'OverTheWire', url: 'https://www.overthewire.org/wargames/', purpose: 'Online platform hosting a variety of war games for different skill levels', },
        { name: 'Pwnable.kr', url: 'https://pwnable.kr/', purpose: 'Online platform hosting various challenges related to binary exploitation and reverse engineering', },
        { name: 'Hacker101', url: 'https://www.hacker101.com/', purpose: 'Online platform offering web security challenges and tutorials for beginners and experts', },
        { name: 'Root Me', url: 'https://www.root-me.org/', purpose: 'Online platform offering challenges in various categories such as web security, cryptography, and more', },
        { name: 'CTF365', url: 'https://ctf365.com/', purpose: 'Online platform providing Capture The Flag (CTF) challenges to improve cybersecurity skills', },
        { name: 'Vulnhub', url: 'https://www.vulnhub.com/', purpose: 'Platform providing vulnerable virtual machines for practicing penetration testing', },
        { name: 'Cybrary', url: 'https://www.cybrary.it', purpose: 'Online cybersecurity and IT training platform offering courses, labs, and assessments' },
        { name: 'Pluralsight', url: 'https://www.pluralsight.com', purpose: 'Technology skills platform offering courses in software development, IT ops, cybersecurity, and more' },
        { name: 'Udemy', url: 'https://www.udemy.com', purpose: 'Online learning platform offering a wide range of courses, including cybersecurity and ethical hacking' },
        { name: 'Coursera', url: 'https://www.coursera.org', purpose: 'Online learning platform offering courses from universities and colleges, including cybersecurity specializations' },
        { name: 'edX', url: 'https://www.edx.org', purpose: 'Online learning platform offering courses from universities and institutions worldwide, including cybersecurity' },
        { name: 'Hack The Box', url: 'https://www.hackthebox.eu', purpose: 'Online platform providing hacking challenges and cybersecurity competitions to enhance skills' },
        { name: 'TryHackMe', url: 'https://www.tryhackme.com', purpose: 'Online platform providing virtual labs and challenges for learning cybersecurity skills' },
        { name: 'CyberPatriot', url: 'https://www.uscyberpatriot.org', purpose: 'National youth cyber education program providing online cybersecurity competitions and training' },
        { name: 'HackEDU', url: 'https://www.hackedu.io', purpose: 'Online platform offering interactive cybersecurity training exercises and challenges' },
        { name: 'SANS Cyber Aces', url: 'https://www.cyberaces.org', purpose: 'Online cybersecurity training platform offering free courses and challenges' }, 
        { name: 'Tor', url: 'https://www.torproject.org', purpose: 'Privacy-focused web browser and network used to browse anonymously and access the dark web' },
        { name: 'H4ckForums', url: 'https://www.hackforums.net', purpose: 'Online community for discussing hacking techniques, cybersecurity, and technology' }
    ];

    return (
        <div className="boxcontainer" id="Hackingcourse">
            <ul className="listAll">
                <div style={{ width: "40%" }}>
                    <h2 className="heading">List of Hacking  Platforms</h2>
                    <hr style={{ color: "rgb(0, 255, 0)", marginTop: "3px" }} />
                </div>
                {HackrMediaPlatfrms.map(website => (
                    <li key={website.name} className="all-items">
                        <span className="website-name">{website.name} - </span>
                        <a href={website.url} className="website-link" target="_blank" rel="noopener noreferrer">
                            {website.purpose}
                        </a>
                        <span className="verified">Verified</span>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default hackingcourse
