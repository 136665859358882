import { BrowserRouter, Routes, Route } from "react-router-dom";
import AItools from "./components/pages/AItools";
import "./App.css";
import Coding from "./components/pages/coding";
import Crypto from "./components/pages/crypto";
import Entertainment from "./components/pages/entertainment";
import Government from "./components/pages/government";
import Hosting from "./components/pages/hosting";
import Header from "./components/inc/Header/Header";
import Study from "./components/pages/study";
import News from "./components/pages/news";
import Shopping from "./components/pages/shopping";
import Home from "./components/home/Home";
import Searchengine from "./components/pages/searchengine";
import Travel from "./components/pages/travel";
import Usesfulllinks from "./components/pages/usesfulllinks";
import Doucuments from "./components/pages/doucuments";
import Stockmarket from "./components/pages/stockmarket";
import Socialmed from "./components/pages/socialmed";
import Sport from "./components/pages/sport";
import Hackingcourse from "./components/pages/hackingcourse";
import ContactUs from "./components/pages/contactus";
import Footer from "./components/inc/Footer/Footer";
import Aboutus from "./components/pages/aboutus";
import Loader from "./components/pages/loader";
import { useEffect, useState } from "react";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 4000); 

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <Loader/>;
  }
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route exact path="*" element={<Home />} />
        <Route path="News" element={<News />} />
        <Route path="Socialmed" element={<Socialmed />} />
        <Route path="Study" element={<Study />} />
        <Route path="Header" element={<Header />} />
        <Route path="Hosting" element={<Hosting />} />
        <Route path="Government" element={<Government />} />
        <Route path="Entertainment" element={<Entertainment />} />
        <Route path="Shopping" element={<Shopping />} />
        <Route path="AItools" element={<AItools />} />
        <Route path="Coding" element={<Coding />} />
        <Route path="Crypto" element={<Crypto />} />
        <Route path="Travel" element={<Travel />} />
        <Route path="Usesfulllinks" element={<Usesfulllinks />} />
        <Route path="Doucuments" element={<Doucuments />} />
        <Route path="Searchengine" element={<Searchengine />} />
        <Route path="Stockmarket" element={<Stockmarket />} />
        <Route path="Sport" element={<Sport />} />
        <Route path="Hackingcourse" element={<Hackingcourse/>} />
        <Route path="ContactUs" element={<ContactUs />} />
        <Route path="Aboutus" element={<Aboutus/>} />
      </Routes>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;

