import React, { Fragment, } from 'react';
import { NavLink } from 'react-router-dom';
import './Header.css';
import { useState, useEffect } from 'react';
import adimg from "../../../assets/adbanner.jpeg"
import img from "../../../assets/logo.png"


const Header = () => {
  const [isTyping, setIsTyping] = useState(false);
  const contentToType = "Links - Forums";
  const [typedContent, setTypedContent] = useState('');

  useEffect(() => {
    if (isTyping) {
      const typingDelay = 250;
      const timeout = setTimeout(() => {
        const nextContent = (typedContent === contentToType) ? '' : contentToType.substring(0, typedContent.length + 1);
        setTypedContent(nextContent);
      }, typingDelay);

      return () => clearTimeout(timeout);
    }
  }, [isTyping, typedContent, contentToType]);


  useEffect(() => {
    const typingTimeout = setTimeout(() => {
      setIsTyping(true);
    }, 1000);
    return () => clearTimeout(typingTimeout);
  }, [typedContent]);



  return (
    <Fragment>
      <div className="marqueetag" behavior="scroll" direction="left">
        <div>
          <p className='updating'>
            We are regularly updating new fresh links</p>
              <hr style={{ color: "rgb(0, 255, 0)", marginTop: "10px" }} />
        </div>
      </div>
      <div class="container">
        <div class="content-div">
          <h1>
          <img src={img} style={{height:"40px"}} alt='logo'/>
            {typedContent}
            <img src={img} style={{height:"40px"}} alt='logo'/></h1>
        </div>
      </div>
      <div className="menu-container">
        <header>
          <nav>
            <ul className="menu-list">
              <li><NavLink to="/Home" smooth>Home</NavLink><span className="separator" style={{ color: "white" }}>||</span></li>
              <li><NavLink to="/aitools" smooth>AI Tools</NavLink><span className="separator" style={{ color: "white" }}>||</span></li>
              <li><NavLink to="/government" smooth>Government</NavLink><span className="separator" style={{ color: "white" }}>||</span></li>
              <li><NavLink to="/entertainment" smooth>Entertainment</NavLink><span className="separator" style={{ color: "white" }}>||</span></li>
              <li><NavLink to="/coding" smooth>Coding</NavLink><span className="separator" style={{ color: "white" }}>||</span></li>
              <li><NavLink to="/Hackingcourse" smooth>Hacking<span className="separator" style={{ color: "white" }}>||</span></NavLink></li>
              <li><NavLink to="/study" smooth>Study</NavLink><span className="separator" style={{ color: "white" }}>||</span></li>
              <li><NavLink to="/hosting" smooth>Hosting</NavLink><span className="separator" style={{ color: "white" }}>||</span></li>
              <li><NavLink to="/news" smooth>News</NavLink><span className="separator" style={{ color: "white" }}>||</span></li>
              <li><NavLink to="/shopping" smooth>Shopping</NavLink><span className="separator" style={{ color: "white" }}>||</span></li>
              <li><NavLink to="/crypto" smooth>Crypto</NavLink><span className="separator " style={{ color: "white" }}>||</span></li>
              <li><NavLink to="/stockmarket" smooth>Stock Market</NavLink><span className="separator" style={{ color: "white" }}>||</span></li>
              <li><NavLink to="/Socialmed" smooth>Social Media</NavLink><span className="separator" style={{ color: "white" }}>||</span></li>
              <li><NavLink to="/Doucuments" smooth>Documents</NavLink><span className="separator" style={{ color: "white" }}>||</span></li>
              <li><NavLink to="/Searchengine" smooth>Search Engine</NavLink><span className="separator" style={{ color: "white" }}>||</span></li>
              <li><NavLink to="/Usesfulllinks" smooth>Useful Links</NavLink><span className="separator" style={{ color: "white" }}>||</span></li>
              <li><NavLink to="/Travel" smooth>Travel <span className="separator" style={{ color: "white" }}>||</span></NavLink></li>
              <li><NavLink to="/Sport" smooth>Sport<span className="separator" style={{ color: "white" }}>||</span></NavLink></li>
              <li><NavLink to="/ContactUs" style={{ color: "red" }} smooth>ContactUs<span className="separator" style={{ color: "white" }}>||</span></NavLink></li>
              <li><NavLink to="/Aboutus" style={{ color: "red" }} smooth>About Us<span className="separator" style={{ color: "white" }}>||</span></NavLink></li>
            </ul>
          </nav>
        </header>
        <hr style={{ color: "rgb(0, 255, 0)", marginTop: "30px" }} />
        <div className="ad">
          <h5 style={{color:"white" ,fontSize:"7px" , textTransform:"capitalize" ,border:"1px dotted", width:"fit-content" ,padding:"5px"}}>advertisement</h5>
          <NavLink to="https://1wooxx.life/#1cpw" target='_blank'>  <img src={adimg} alt='ad' className="ad" /></NavLink>

        </div>
      </div>

    </Fragment>

  );
}

export default Header;
