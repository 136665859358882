import React from 'react'

const shopping = () => {
  const shoppingWebsites = [
    // United States
    { name: 'Amazon', url: 'https://www.amazon.com', purpose: 'Online marketplace offering a wide range of products' },
    { name: 'eBay', url: 'https://www.ebay.com', purpose: 'Online auction and shopping website' },
    { name: 'Walmart', url: 'https://www.walmart.com', purpose: 'Retail corporation offering a variety of products online' },
    { name: 'Best Buy', url: 'https://www.bestbuy.com', purpose: 'Retailer specializing in consumer electronics and appliances' },
    { name: 'Macy\'s', url: 'https://www.macys.com', purpose: 'American department store chain offering clothing, accessories, and home goods' },
    { name: 'Costco', url: 'https://www.costco.com', purpose: 'Membership-based retail store offering a wide range of products at wholesale prices' },
    { name: 'Newegg', url: 'https://www.newegg.com', purpose: 'Online retailer specializing in computer hardware and electronics' },
    // India
    { name: 'Flipkart', url: 'https://www.flipkart.com', purpose: 'Indian e-commerce company offering a wide range of products' },
    { name: 'Myntra', url: 'https://www.myntra.com', purpose: 'Indian fashion e-commerce platform offering clothing, accessories, and footwear' },
    { name: 'BigBasket', url: 'https://www.bigbasket.com', purpose: 'Indian online grocery and food products provider' },
    { name: 'Nykaa', url: 'https://www.nykaa.com', purpose: 'Indian beauty and wellness e-commerce platform offering cosmetics, skincare, and more' },
    { name: 'Tata Cliq', url: 'https://www.tatacliq.com', purpose: 'Indian e-commerce platform offering electronics, fashion, and more' },
    // China
    { name: 'Alibaba', url: 'https://www.alibaba.com', purpose: 'Chinese e-commerce company providing a platform for global wholesale trade' },
    { name: 'Taobao', url: 'https://www.taobao.com', purpose: 'Chinese online shopping website facilitating consumer-to-consumer and business-to-consumer sales' },
    { name: 'JD.com', url: 'https://www.jd.com', purpose: 'Chinese e-commerce company offering a wide range of products' },
    // United Kingdom
    { name: 'ASOS', url: 'https://www.asos.com', purpose: 'British online fashion retailer offering clothing, accessories, and beauty products' },
    { name: 'Argos', url: 'https://www.argos.co.uk', purpose: 'British catalogue retailer offering a wide range of products' },
    // Japan
    { name: 'Rakuten', url: 'https://www.rakuten.com', purpose: 'Japanese e-commerce company offering various products and services' },
    { name: 'Amazon Japan', url: 'https://www.amazon.co.jp', purpose: 'Japanese version of Amazon offering a wide range of products' },
    // Germany
    { name: 'Zalando', url: 'https://www.zalando.com', purpose: 'European e-commerce company specializing in fashion and clothing' },
    { name: 'Otto', url: 'https://www.otto.de', purpose: 'German online retailer offering a wide range of products' },
    // Canada
    { name: 'Best Buy Canada', url: 'https://www.bestbuy.ca', purpose: 'Canadian retailer offering consumer electronics and appliances' },
    { name: 'Indigo', url: 'https://www.chapters.indigo.ca', purpose: 'Canadian bookstore chain offering books, gifts, and more' },
    // France
    { name: 'Fnac', url: 'https://www.fnac.com', purpose: 'French retail chain offering electronics, books, and more' },
    { name: 'Cdiscount', url: 'https://www.cdiscount.com', purpose: 'French e-commerce website offering a wide range of products' },
    // Australia
    { name: 'Kogan', url: 'https://www.kogan.com', purpose: 'Australian online retailer offering a wide range of products' },
    { name: 'The Iconic', url: 'https://www.theiconic.com.au', purpose: 'Australian online fashion and sportswear retailer' },
    // South Korea
    { name: 'Gmarket', url: 'http://www.gmarket.co.kr', purpose: 'South Korean online marketplace offering various products' },
    { name: '11st', url: 'http://www.11st.co.kr', purpose: 'South Korean online shopping website offering a wide range of products' }
  ];
  
  
  return (
    
    <div className="boxcontainer">
      <ul className="listAll">
      <div style={{ width: "40%" }}>
          <h2 className="heading">List of Shopping</h2>
          <hr style={{ color: "rgb(0, 255, 0)", marginTop: "3px" }} />
        </div>
        {shoppingWebsites.map(website => (
          <li key={website.name} className="all-items">
            <span className="website-name">{website.name} - </span>
            <a href={website.url} className="website-link" target="_blank" rel="noopener noreferrer">
              {website.purpose}
            </a>
            <span className="verified">Verified</span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default shopping
