import React from 'react'

const hosting = () => {
  const hostingWebsites = [
    { name: 'GoDaddy', url: 'https://www.godaddy.com/', purpose: 'Domain Name Registration and Web Hosting' },
    { name: 'Bluehost', url: 'https://www.bluehost.com/', purpose: 'Web Hosting and Domain Name Registration' },
    { name: 'HostGator', url: 'https://www.hostgator.com/', purpose: 'Web Hosting Services' },
    { name: 'SiteGround', url: 'https://www.siteground.com/', purpose: 'Web Hosting Services' },
    { name: 'DreamHost', url: 'https://www.dreamhost.com/', purpose: 'Web Hosting Services' },
    { name: 'Namecheap', url: 'https://www.namecheap.com/', purpose: 'Domain Name Registration and Web Hosting' },
    { name: 'WP Engine', url: 'https://wpengine.com/', purpose: 'WordPress Hosting and Managed WordPress Solutions' },
    { name: 'Hostinger', url: 'https://www.hostinger.com/', purpose: 'Web Hosting Services' },
    { name: 'A2 Hosting', url: 'https://www.a2hosting.com/', purpose: 'Web Hosting Services' },
    { name: 'InMotion Hosting', url: 'https://www.inmotionhosting.com/', purpose: 'Web Hosting Services' },
  ];
  
  return (
    <div className="boxcontainer">
      <ul className="listAll">
      <div style={{ width: "40%" }}>
          <h2 className="heading">List of Hosting</h2>
          <hr style={{ color: "rgb(0, 255, 0)", marginTop: "3px" }} />
        </div>
        {hostingWebsites.map(website => (
          <li key={website.name} className="all-items">
            <span className="website-name">{website.name} - </span>
            <a href={website.url} className="website-link" target="_blank" rel="noopener noreferrer">
              {website.purpose}
            </a>
            <span className="verified">Verified</span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default hosting
