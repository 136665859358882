import React from 'react'

const travel = () => {
    const travelWebsites = [
        { name: 'Expedia', url: 'https://www.expedia.com', purpose: 'Online Travel Agency' },
        { name: 'Booking.com', url: 'https://www.booking.com', purpose: 'Accommodation Booking Platform' },
        { name: 'TripAdvisor', url: 'https://www.tripadvisor.com', purpose: 'Traveler Reviews and Recommendations' },
        { name: 'Airbnb', url: 'https://www.airbnb.com', purpose: 'Short-term Accommodation Rentals' },
        { name: 'Skyscanner', url: 'https://www.skyscanner.com', purpose: 'Flight, Hotel, and Car Rental Search Engine' },
        { name: 'Kayak', url: 'https://www.kayak.com', purpose: 'Travel Search Engine' },
        { name: 'Hotels.com', url: 'https://www.hotels.com', purpose: 'Hotel Booking Platform' },
        { name: 'Agoda', url: 'https://www.agoda.com', purpose: 'Accommodation Booking Platform' },
        { name: 'Lonely Planet', url: 'https://www.lonelyplanet.com', purpose: 'Travel Guides and Information' },
        { name: 'Google Flights', url: 'https://www.google.com/flights', purpose: 'Flight Search Engine by Google' },
        { name: 'VRBO', url: 'https://www.vrbo.com', purpose: 'Vacation Rental Marketplace' },
        { name: 'Hostelworld', url: 'https://www.hostelworld.com', purpose: 'Hostel Booking Platform' },
        { name: 'Rentalcars.com', url: 'https://www.rentalcars.com', purpose: 'Car Rental Booking Platform' },
        { name: 'Viator', url: 'https://www.viator.com', purpose: 'Travel Activities and Tours Booking Platform' },
        { name: 'Couchsurfing', url: 'https://www.couchsurfing.com', purpose: 'Hospitality Service and Social Networking Platform' },
        { name: 'MakeMyTrip', url: 'https://www.makemytrip.com', purpose: 'Online Travel Agency' },
        { name: 'Goibibo', url: 'https://www.goibibo.com', purpose: 'Travel Booking Platform' },
        { name: 'Cleartrip', url: 'https://www.cleartrip.com', purpose: 'Travel Aggregator and Booking Platform' },
        { name: 'Yatra', url: 'https://www.yatra.com', purpose: 'Travel Booking and Information Platform' },
        { name: 'IRCTC', url: 'https://www.irctc.co.in', purpose: 'Indian Railway Catering and Tourism Corporation' },
        { name: 'RedBus', url: 'https://www.redbus.in', purpose: 'Bus Ticket Booking Platform' },
        { name: 'OYO', url: 'https://www.oyorooms.com', purpose: 'Budget Hotel and Accommodation Booking Platform' },
        { name: 'GoAir', url: 'https://www.goair.in', purpose: 'Low-Cost Airline' },
        { name: 'IndiGo', url: 'https://www.goindigo.in', purpose: 'Low-Cost Airline' },
        { name: 'SpiceJet', url: 'https://www.spicejet.com', purpose: 'Low-Cost Airline' },
        { name: 'Ixigo', url: 'https://www.ixigo.com', purpose: 'Travel Search Engine and Aggregator' },
        { name: 'Thomas Cook India', url: 'https://www.thomascook.in', purpose: 'Travel Agency and Tour Operator' },
        { name: 'SOTC India', url: 'https://www.sotc.in', purpose: 'Travel Agency and Tour Operator' },
        { name: 'TravelTriangle', url: 'https://www.traveltriangle.com', purpose: 'Online Platform for Customized Holiday Packages' },
        { name: 'EaseMyTrip', url: 'https://www.easemytrip.com', purpose: 'Online Travel Agency' }
      ];
      
  return (
    <div className="boxcontainer">
    <ul className="listAll">
    <div style={{ width: "40%" }}>
          <h2 className="heading">List of Travel</h2>
          <hr style={{ color: "rgb(0, 255, 0)", marginTop: "3px" }} />
        </div>
      {travelWebsites.map(website => (
        <li key={website.name} className="all-items">
          <span className="website-name">{website.name} - </span>
          <a href={website.url} className="website-link" target="_blank" rel="noopener noreferrer">
            {website.purpose}
          </a>
          <span className="verified">Verified</span>
        </li>
      ))}
    </ul>
  </div>
  )
}

export default travel
