import React from "react";
import Aitools from "../pages/AItools";
import Government from "../pages/government";
import Entertainment from "../pages/entertainment";
import Coding from "../pages/coding";
import Hackingcourse from "../pages/hackingcourse";
import Study from "../pages/study";
import Hosting from "../pages/hosting";
import News from "../pages/news";
import Shopping from "../pages/shopping";
import Crypto from "../pages/crypto";
import Stockmarket from "../pages/stockmarket";
import Socialmed from "../pages/socialmed";
import Doucuments from "../pages/doucuments";
import Searchengine from "../pages/searchengine";
import Usesfulllinks from "../pages/usesfulllinks";
import Travel from "../pages/travel";


const Home = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  return (
    <div>
      <Aitools />
      <Government />
      <Entertainment />
      <Coding />  
      <Hackingcourse/>
      <Study />
      <Hosting />
      <News />
      <Shopping />
      <Crypto />
      <Stockmarket />
      <Socialmed />
      <Doucuments />
      <Searchengine />
      <Usesfulllinks />
      <Travel />
      <button onClick={scrollToTop} className="back-to-top-button">
      <span>&#10228;</span>
      </button>
    </div>
  );
};


export default Home;
