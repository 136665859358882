import React from "react";
import "./Footer.css"; // Import the CSS file for styling

const Footer = () => {
  return (
    <footer className="hacker-footer">
      <div className="footer-bottom">
        <p>
          This website operates in anonymity. 
        </p>
        <div className="illuminati-symbol">
          <i className="fas fa-eye"></i>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
