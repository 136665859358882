import React from 'react'

const stockmarket = () => {
    const investmentWebsites = [
        { name: 'E*TRADE', url: 'https://us.etrade.com', purpose: 'Online brokerage platform for trading stocks, ETFs, options, and more' },
        { name: 'Robinhood', url: 'https://robinhood.com', purpose: 'Commission-free trading platform for stocks, ETFs, options, and cryptocurrencies' },
        { name: 'Interactive Brokers', url: 'https://www.interactivebrokers.com', purpose: 'Online brokerage platform offering trading in stocks, options, futures, forex, bonds, and funds' },
        { name: 'Fidelity Investments', url: 'https://www.fidelity.com', purpose: 'Full-service brokerage firm offering a wide range of investment products and services' },
        { name: 'Vanguard', url: 'https://investor.vanguard.com', purpose: 'Investment management company offering mutual funds, ETFs, and retirement planning services' },
        { name: 'Zerodha', url: 'https://zerodha.com', purpose: 'Indian discount brokerage firm offering trading in stocks, commodities, and currencies' },
        { name: 'Upstox', url: 'https://upstox.com', purpose: 'Online discount brokerage platform for trading stocks, commodities, and derivatives' },
        { name: 'Groww', url: 'https://groww.in', purpose: 'Online platform for mutual fund investments and stock trading in India' },
        { name: 'ICICI Direct', url: 'https://www.icicidirect.com', purpose: 'Online trading and investment platform by ICICI Securities, offering stocks, derivatives, mutual funds, and more' },
        { name: 'Charles Schwab', url: 'https://www.schwab.com', purpose: 'Full-service brokerage firm offering investment services, trading, and financial planning' },
        { name: 'TD Ameritrade', url: 'https://www.tdameritrade.com', purpose: 'Online brokerage platform offering trading in stocks, ETFs, options, and more' },
        { name: 'Ally Invest', url: 'https://www.ally.com/invest', purpose: 'Online brokerage platform offering trading in stocks, ETFs, options, and forex' },
        { name: 'Wealthfront', url: 'https://www.wealthfront.com', purpose: 'Automated investing service offering robo-advisor portfolios and financial planning' },
        { name: 'Betterment', url: 'https://www.betterment.com', purpose: 'Online investment platform offering robo-advisor services and financial planning' },
        { name: 'Yahoo Finance', url: 'https://finance.yahoo.com', purpose: 'Financial news, data, and analysis platform' },
        { name: 'Bloomberg', url: 'https://www.bloomberg.com', purpose: 'Global business and financial news organization' },
        { name: 'CNBC', url: 'https://www.cnbc.com', purpose: 'Business news and financial information network' },
        { name: 'MarketWatch', url: 'https://www.marketwatch.com', purpose: 'Financial information and business news website' },
        { name: 'Investopedia', url: 'https://www.investopedia.com', purpose: 'Educational website providing financial content, news, and analysis' },
        { name: 'Morningstar', url: 'https://www.morningstar.com', purpose: 'Investment research and management firm providing data and analysis on stocks, mutual funds, and ETFs' },
        { name: 'Seeking Alpha', url: 'https://seekingalpha.com', purpose: 'Stock market analysis, financial news, and investment research platform' },
        { name: 'The Motley Fool', url: 'https://www.fool.com', purpose: 'Financial services company providing investment advice, stock research, and analysis' },
        { name: 'Reuters', url: 'https://www.reuters.com', purpose: 'International news organization providing financial news and market data' },
        { name: 'CNN Business', url: 'https://www.cnn.com/business', purpose: 'Business news and financial information website by CNN' }
      ];
      
    return (


        <div className="boxcontainer">
          <ul className="listAll">
          <div style={{ width: "40%" }}>
          <h2 className="heading">List of Stock Market</h2>
          <hr style={{ color: "rgb(0, 255, 0)", marginTop: "3px" }} />
        </div>
    
            {investmentWebsites.map(website => (
              <li key={website.name} className="all-items">
                <span className="website-name">{website.name} - </span>
                <a href={website.url} className="website-link" target="_blank" rel="noopener noreferrer">
                  {website.purpose}
                </a>
                <span className="verified">Verified</span>
              </li>
            ))}
          </ul>
        </div>
      );
}

export default stockmarket
