import React from 'react';

const Crypto = () => {
  const cryptowebsites = [
    { name: 'Coinbase', url: 'https://www.coinbase.com', purpose: 'Buy/Sell Cryptocurrency' },
    { name: 'Binance', url: 'https://www.binance.com', purpose: 'Trading Platform' },
    { name: 'CoinMarketCap', url: 'https://coinmarketcap.com', purpose: 'Cryptocurrency Market Data' },
    { name: 'CoinDesk', url: 'https://www.coindesk.com', purpose: 'Cryptocurrency News' },
    { name: 'CryptoCompare', url: 'https://www.cryptocompare.com', purpose: 'Crypto Prices & Charts' },
    { name: 'Kraken', url: 'https://www.kraken.com', purpose: 'Cryptocurrency Exchange' },
    { name: 'Bitfinex', url: 'https://www.bitfinex.com', purpose: 'Cryptocurrency Trading Platform' },
    { name: 'Gemini', url: 'https://gemini.com', purpose: 'Cryptocurrency Exchange and Custodian' },
    { name: 'Huobi', url: 'https://www.huobi.com', purpose: 'Digital Asset Exchange' },
    { name: 'FTX', url: 'https://ftx.com', purpose: 'Cryptocurrency Derivatives Exchange' }
  ];

  return (
    <div className="boxcontainer">
      <ul className="listAll">
      <div style={{ width: "40%" }}>
          <h2 className="heading">List of crypto</h2>
          <hr style={{ color: "rgb(0, 255, 0)", marginTop: "3px" }} />
        </div>
        {cryptowebsites.map(website => (
          <li key={website.name} className="all-items">
            <span className="website-name">{website.name} - </span>
            <a href={website.url} className="website-link" target="_blank" rel="noopener noreferrer">
              {website.purpose}
            </a>
            <span className="verified">Verified</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Crypto;
