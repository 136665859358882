import React from 'react'

const study = () => {
  const studyResources = [
    // General Study Resources
    { name: 'Khan Academy', url: 'https://www.khanacademy.org', purpose: 'Online learning platform covering a wide range of subjects from K-12 to college-level' },
    { name: 'Coursera', url: 'https://www.coursera.org', purpose: 'Online platform offering courses from universities and organizations worldwide' },
    { name: 'edX', url: 'https://www.edx.org', purpose: 'Online course provider offering university-level courses in various disciplines' },
    { name: 'Udemy', url: 'https://www.udemy.com', purpose: 'Online marketplace for learning and teaching various skills and subjects' },
    { name: 'Duolingo', url: 'https://www.duolingo.com', purpose: 'Language learning platform offering courses in multiple languages' },
    { name: 'Quizlet', url: 'https://www.quizlet.com', purpose: 'Online study tools including flashcards, quizzes, and games' },
    // Academic Research
    { name: 'Google Scholar', url: 'https://scholar.google.com', purpose: 'Search engine for scholarly articles, theses, books, and court opinions' },
    { name: 'JSTOR', url: 'https://www.jstor.org', purpose: 'Digital library providing access to academic journals, books, and primary sources' },
    { name: 'PubMed', url: 'https://pubmed.ncbi.nlm.nih.gov', purpose: 'Search engine for biomedical and life sciences literature' },
    { name: 'IEEE Xplore', url: 'https://ieeexplore.ieee.org', purpose: 'Digital library for research in engineering and technology' },
    { name: 'ScienceDirect', url: 'https://www.sciencedirect.com', purpose: 'Scientific database providing access to research articles in various fields' },
    // Coding and Computer Science
    { name: 'Stack Overflow', url: 'https://stackoverflow.com', purpose: 'Q&A community for programmers and developers' },
    { name: 'GitHub', url: 'https://github.com', purpose: 'Platform for hosting and collaborating on Git repositories' },
    { name: 'Codecademy', url: 'https://www.codecademy.com', purpose: 'Online interactive platform for learning to code' },
    { name: 'LeetCode', url: 'https://leetcode.com', purpose: 'Platform for coding challenges and interview preparation' },
    { name: 'GeeksforGeeks', url: 'https://www.geeksforgeeks.org', purpose: 'Computer science portal for interview preparation and coding practice' },
    // Mathematics
    { name: 'Wolfram Alpha', url: 'https://www.wolframalpha.com', purpose: 'Computational knowledge engine for answering questions and solving problems' },
    { name: 'Mathway', url: 'https://www.mathway.com', purpose: 'Math problem solver for algebra, calculus, and other math subjects' },
    { name: 'Desmos', url: 'https://www.desmos.com', purpose: 'Online graphing calculator for plotting functions and exploring math concepts' },
    // Arts and Humanities
    { name: 'Kadenze', url: 'https://www.kadenze.com', purpose: 'Online courses in art, music, design, and creative technology' },
    { name: 'Courtauld Institute of Art', url: 'https://courtauld.ac.uk', purpose: 'Leading center for the study of art history, conservation, and curating' },
    { name: 'The British Library', url: 'https://www.bl.uk', purpose: 'National library of the United Kingdom offering extensive resources for research and study' }
  ];
  
  return (


    <div className="boxcontainer">
      <ul className="listAll">
      <div style={{ width: "40%" }}>
          <h2 className="heading">List of  Study</h2>
          <hr style={{ color: "rgb(0, 255, 0)", marginTop: "3px" }} />
        </div>
        {studyResources.map(website => (
          <li key={website.name} className="all-items">
            <span className="website-name">{website.name} - </span>
            <a href={website.url} className="website-link" target="_blank" rel="noopener noreferrer">
              {website.purpose}
            </a>
            <span className="verified">Verified</span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default study
