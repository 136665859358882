import React from 'react'
const Socialmed = () => {
    const SocialmediaPlatforms = [
      { name: 'Facebook', url: 'https://www.facebook.com', purpose: 'Social networking platform for connecting with friends and family' },
      { name: 'Instagram', url: 'https://www.instagram.com', purpose: 'Photo and video sharing social networking service' },
      { name: 'Twitter', url: 'https://www.twitter.com', purpose: 'Social networking and microblogging platform' },
      { name: 'LinkedIn', url: 'https://www.linkedin.com', purpose: 'Professional networking platform for job seekers, professionals, and businesses' },
      { name: 'Snapchat', url: 'https://www.snapchat.com', purpose: 'Multimedia messaging app for sharing photos and videos' },
      { name: 'Pinterest', url: 'https://www.pinterest.com', purpose: 'Image sharing and social media platform designed for discovering and saving creative ideas' },
      { name: 'Reddit', url: 'https://www.reddit.com', purpose: 'Social news aggregation, web content rating, and discussion website' },
      { name: 'TikTok', url: 'https://www.tiktok.com', purpose: 'Short-form video sharing platform' },
      { name: 'YouTube', url: 'https://www.youtube.com', purpose: 'Video sharing platform for uploading and viewing a wide variety of user-generated and corporate media videos' },
      { name: 'WhatsApp', url: 'https://www.whatsapp.com', purpose: 'Instant messaging app for sending text messages, voice messages, and video calls' }
    ];
    
    return (
      <div className="boxcontainer">
        <ul className="listAll">
        <div style={{ width: "40%" }}>
          <h2 className="heading">List of Socialmedia</h2>
          <hr style={{ color: "rgb(0, 255, 0)", marginTop: "3px" }} />
        </div> 
          {SocialmediaPlatforms.map(website => (
            <li key={website.name} className="all-items">
              <span className="website-name">{website.name} - </span>
              <a href={website.url} className="website-link" target="_blank" rel="noopener noreferrer">
                {website.purpose}
              </a>
              <span className="verified">Verified</span>
            </li>
          ))}
        </ul>
      </div>
    );
  }

export default Socialmed
