import React from 'react'

const usesfulllinks = () => {
  const usefulLinks = [

    { name: 'Wikipedia', url: 'https://www.wikipedia.org', purpose: 'Online encyclopedia for general reference' },
    { name: 'YouTube', url: 'https://www.youtube.com', purpose: 'Video sharing platform for watching and sharing videos' },
    { name: 'Google Drive', url: 'https://drive.google.com', purpose: 'Cloud storage and file backup service by Google' },
    { name: 'Dropbox', url: 'https://www.dropbox.com', purpose: 'Cloud storage service for storing and sharing files' },
    { name: 'Google Maps', url: 'https://www.google.com/maps', purpose: 'Mapping service for navigation and directions' },
    { name: 'PayPal', url: 'https://www.paypal.com', purpose: 'Online payment service for sending and receiving money' },
    { name: 'Venmo', url: 'https://www.venmo.com', purpose: 'Mobile payment service for sending and receiving money' },
    { name: 'Mint', url: 'https://www.mint.com', purpose: 'Personal finance management tool for budgeting and tracking expenses' },
    { name: 'Coinbase', url: 'https://www.coinbase.com', purpose: 'Cryptocurrency exchange platform for buying, selling, and managing digital assets' },
    { name: 'Trello', url: 'https://www.trello.com', purpose: 'Project management tool for organizing and prioritizing tasks' },
    { name: 'Todoist', url: 'https://www.todoist.com', purpose: 'Task management app for creating and organizing to-do lists' },
    { name: 'Notion', url: 'https://www.notion.so', purpose: 'All-in-one workspace for note-taking, project management, and task organization' },
    { name: 'Google Calendar', url: 'https://calendar.google.com', purpose: 'Online calendar service by Google for scheduling events and appointments' }
  ];

  return (
    <div className="boxcontainer">
      <ul className="listAll">
      <div style={{ width: "40%" }}>
          <h2 className="heading">List of UsefullLinks</h2>
          <hr style={{ color: "rgb(0, 255, 0)", marginTop: "3px" }} />
        </div>
        {usefulLinks.map(website => (
          <li key={website.name} className="all-items">
            <span className="website-name">{website.name} - </span>
            <a href={website.url} className="website-link" target="_blank" rel="noopener noreferrer">
              {website.purpose}
            </a>
            <span className="verified">Verified</span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default usesfulllinks
