import React from 'react'

const Goverment = () => {
  const governmentServices = [
    { name: 'Government of India', url: 'https://india.gov.in/', purpose: 'Official Portal of the Government of India' },
    { name: 'USA.gov', url: 'https://www.usa.gov/', purpose: 'Official Website of the United States Government' },
    { name: 'Gov.uk', url: 'https://www.gov.uk/', purpose: 'Official Website of the UK Government' },
    { name: 'Service Canada', url: 'https://www.canada.ca/en/services.html', purpose: 'Government Services for Canadians' },
    { name: 'Australian Government', url: 'https://www.australia.gov.au/', purpose: 'Official Website of the Australian Government' },
    { name: 'Government of Singapore', url: 'https://www.gov.sg/', purpose: 'Official Portal of the Government of Singapore' },
    { name: 'Government of Japan', url: 'https://www.gov-online.go.jp/index.html', purpose: 'Official Portal of the Government of Japan' },
    { name: 'Government of South Africa', url: 'https://www.gov.za/', purpose: 'Official Portal of the Government of South Africa' },
    { name: 'Government of Brazil', url: 'http://www.brasil.gov.br/', purpose: 'Official Portal of the Government of Brazil' },
    { name: 'Government of Russia', url: 'http://government.ru/en/', purpose: 'Official Website of the Government of the Russian Federation' },
    { name: 'Government of France', url: 'https://www.gouvernement.fr/en', purpose: 'Official Website of the French Government' },
    { name: 'Government of Germany', url: 'https://www.bundesregierung.de/breg-en', purpose: 'Official Website of the German Government' },
    { name: 'Government of China', url: 'http://english.www.gov.cn/', purpose: 'Official Website of the Government of China' },
    { name: 'Government of India Passport Seva', url: 'https://portal2.passportindia.gov.in/AppOnlineProject/welcomeLink', purpose: 'Passport Services by the Government of India' },
    { name: 'India Post', url: 'https://www.indiapost.gov.in/', purpose: 'Postal and Courier Services in India' },
    { name: 'Aadhaar', url: 'https://uidai.gov.in/', purpose: 'Unique Identification Authority of India (Aadhaar Card)' },
    { name: 'PAN', url: 'https://www.incometaxindia.gov.in/_layouts/15/panodlink/home.aspx', purpose: 'Permanent Account Number (PAN Card)' },
    { name: 'Voter ID', url: 'https://www.nvsp.in/', purpose: 'National Voter'},
    { name: 'Driving License', url: 'https://parivahan.gov.in/sarathiservice12/stateSelection.do', purpose: 'Ministry of Road Transport & Highways (Driving License)' }
  ];
  
  return (
    <div className="boxcontainer">
      <ul className="listAll">
      <div style={{ width: "40%" }}>
          <h2 className="heading">List of Government</h2>
          <hr style={{ color: "rgb(0, 255, 0)", marginTop: "3px" }} />
        </div>
        {governmentServices.map(website => (
          <li key={website.name} className="all-items">
            <span className="website-name">{website.name} - </span>
            <a href={website.url} className="website-link" target="_blank" rel="noopener noreferrer">
              {website.purpose}
            </a>
            <span className="verified">Verified</span>
          </li>
        ))}
      </ul>
    </div>
    
  )
}

export default Goverment


