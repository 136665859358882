import React from 'react'

const doucuments = () => {
  const documentWebsites = [
    { name: 'Passport Seva', url: 'https://portal2.passportindia.gov.in/AppOnlineProject/welcomeLink', purpose: 'Government of India Passport Services' },
    { name: 'Aadhaar', url: 'https://uidai.gov.in/', purpose: 'Unique Identification Authority of India (Aadhaar Card)' },
    { name: 'PAN', url: 'https://www.incometaxindia.gov.in/_layouts/15/panodlink/home.aspx', purpose: 'Permanent Account Number (PAN Card)' },
    { name: 'Voter ID', url: 'https://www.nvsp.in/', purpose: 'National Voter' },
    { name: 'Driving License', url: 'https://parivahan.gov.in/sarathiservice12/stateSelection.do', purpose: 'Ministry of Road Transport & Highways (Driving License)' },
    { name: 'Passport Canada', url: 'https://www.canada.ca/en/immigration-refugees-citizenship/services/canadian-passports.html', purpose: 'Canadian Passport Services' },
    { name: 'USA.gov Passports', url: 'https://www.usa.gov/passport', purpose: 'U.S. Passport Services' },
    { name: 'UK Passport Office', url: 'https://www.gov.uk/browse/abroad/passports', purpose: 'UK Passport Services' },
    { name: 'Australian Passport Office', url: 'https://www.passports.gov.au/', purpose: 'Australian Passport Services' },
    { name: 'Passport Japan', url: 'https://www.mofa.go.jp/p_pd/ip/ks_c/m_pages/mexico/requirements.html', purpose: 'Passport Services in Japan' },
    { name: 'Passport South Africa', url: 'http://www.dha.gov.za/index.php/civic-services/passports', purpose: 'South African Passport Services' },
    { name: 'Brazilian Passport', url: 'https://www.gov.br/mre/en/subjects/traveling-to-brazil/entering-brazil/brazilian-passport', purpose: 'Brazilian Passport Services' },
    { name: 'Russian Passport', url: 'https://xn--b1aew.xn--p1ai//en/faq', purpose: 'Russian Passport Services' },
    { name: 'French Passport', url: 'https://www.service-public.fr/particuliers/vosdroits/N358', purpose: 'French Passport Services' },
    { name: 'German Passport', url: 'https://www.bundesdruckerei.de/en/products-services/passports-identity-documents/identity-documents', purpose: 'German Passport Services' },
    { name: 'Chinese Passport', url: 'https://english.www.gov.cn/services/travel/documentapp/index.htm', purpose: 'Chinese Passport Services' },
    { name: 'New Zealand Passport', url: 'https://www.passports.govt.nz/', purpose: 'New Zealand Passport Services' },
    { name: 'South Korean Passport', url: 'https://www.passport.go.kr/', purpose: 'South Korean Passport Services' },
    { name: 'Mexican Passport', url: 'https://www.gob.mx/tramites/ficha/pasaporte-mexicano/SRE189', purpose: 'Mexican Passport Services' },
    { name: 'Italian Passport', url: 'https://www.esteri.it/mae/en/servizi/italiani-all-estero/documenti-di-viaggio/passaporto.html', purpose: 'Italian Passport Services' },
    { name: 'Swiss Passport', url: 'https://www.sem.admin.ch/sem/en/home/publiservice/faq/pass/fees-and-charges-for-passports-and-identity-cards.html', purpose: 'Swiss Passport Services' },
    { name: 'Canadian Social Insurance Number (SIN)', url: 'https://www.canada.ca/en/employment-social-development/services/sin.html', purpose: 'Government of Canada - Social Insurance Number (SIN) Services' },
    { name: 'U.S. Social Security Administration', url: 'https://www.ssa.gov/', purpose: 'Social Security Administration Services' },
    { name: 'UK National Insurance Number', url: 'https://www.gov.uk/apply-national-insurance-number', purpose: 'Government of UK - National Insurance Number Services' },
    { name: 'Indian Overseas Citizen of India (OCI)', url: 'https://passport.gov.in/oci/', purpose: 'Government of India - Overseas Citizen of India (OCI) Services' },
    { name: 'New Zealand Passport', url: 'https://www.passports.govt.nz/', purpose: 'New Zealand Passport Services' },
    { name: 'South Korean Passport', url: 'https://www.passport.go.kr/', purpose: 'South Korean Passport Services' },
    { name: 'Mexican Passport', url: 'https://www.gob.mx/tramites/ficha/pasaporte-mexicano/SRE189', purpose: 'Mexican Passport Services' },
    { name: 'Italian Passport', url: 'https://www.esteri.it/mae/en/servizi/italiani-all-estero/documenti-di-viaggio/passaporto.html', purpose: 'Italian Passport Services' },
    { name: 'Swiss Passport', url: 'https://www.sem.admin.ch/sem/en/home/publiservice/faq/pass/fees-and-charges-for-passports-and-identity-cards.html', purpose: 'Swiss Passport Services' },
    { name: 'Canadian Social Insurance Number (SIN)', url: 'https://www.canada.ca/en/employment-social-development/services/sin.html', purpose: 'Government of Canada - Social Insurance Number (SIN) Services' },
    { name: 'U.S. Social Security Administration', url: 'https://www.ssa.gov/', purpose: 'Social Security Administration Services' },
    { name: 'UK National Insurance Number', url: 'https://www.gov.uk/apply-national-insurance-number', purpose: 'Government of UK - National Insurance Number Services' },
    { name: 'Indian Overseas Citizen of India (OCI)', url: 'https://passport.gov.in/oci/', purpose: 'Government of India - Overseas Citizen of India (OCI) Services' },
  ];
  
  return (
    <div className="boxcontainer">
      <ul className="listAll">
      <div style={{ width: "40%" }}>
          <h2 className="heading">List of Doucuments</h2>
          <hr style={{ color: "rgb(0, 255, 0)", marginTop: "3px" }} />
        </div>
        {documentWebsites.map(website => (
          <li key={website.name} className="all-items">
            <span className="website-name">{website.name} - </span>
            <a href={website.url} className="website-link" target="_blank" rel="noopener noreferrer">
              {website.purpose}
            </a>
            <span className="verified">Verified</span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default doucuments
