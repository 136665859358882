import React from 'react';

const Aitools = () => {
  
  const aiTools = [
    { name: 'OpenAI', url: 'https://www.openai.com', purpose: 'AI Research and Development' },
    { name: 'DeepDream', url: 'https://deepdreamgenerator.com/', purpose: 'Image Generation and Manipulation using Deep Learning' },
    { name: 'Voiceflow', url: 'https://www.voiceflow.com/', purpose: 'Conversational AI Platform for Voice User Interface Development' },
    { name: 'Descript', url: 'https://www.descript.com/', purpose: 'AI-Powered Audio and Video Editing Software with Text-to-Speech' },
    { name: 'Lyrebird AI', url: 'https://lyrebird.ai/', purpose: 'Text-to-Voice Synthesis with Voice Cloning Technology' },
    { name: 'RunwayML', url: 'https://runwayml.com/', purpose: 'Toolkit for Artists and Creators to Use Machine Learning Models' },
    { name: 'Wav2Letter', url: 'https://wav2letter.github.io/', purpose: 'Facebook AI'},
    { name: 'DeOldify', url: 'https://github.com/jantic/DeOldify', purpose: 'AI-Powered Colorization and Restoration of Old Images' },
    { name: 'DeepArt', url: 'https://deepart.io/', purpose: 'AI-Powered Artistic Style Transfer and Image Editing' },
    { name: 'Replika', url: 'https://replika.ai/', purpose: 'AI Chatbot Designed to Learn from and Mimic Human Interaction' },
    { name: 'JukeDeck', url: 'https://www.jukedeck.com/', purpose: 'AI Music Composition and Generation Platform' },
    { name: 'Replica Studios', url: 'https://replicastudios.com/', purpose: 'AI-Powered Voice Actors for Generating Synthetic Voices' },
    { name: 'DALL-E', url: 'https://openai.com/research/dall-e/', purpose: 'OpenAI\'s Image Generation Model Capable of Creating Images from Text Descriptions' },
    { name: 'Voicery', url: 'https://voicery.com/', purpose: 'Text-to-Speech Technology with Natural-Sounding Voices' },
    { name: 'Rosebud.AI', url: 'https://rosebud.ai/', purpose: 'AI-Powered Image Editing and Enhancement Platform' },
    { name: 'Deepgram', url: 'https://deepgram.com/', purpose: 'AI-Powered Speech Recognition and Audio Analysis Platform' },
    { name: 'VoxRemover', url: 'https://voxremover.com/', purpose: 'AI-Powered Tool for Removing Vocals from Music Tracks' },
    { name: 'Lobe', url: 'https://lobe.ai/', purpose: 'Simple Machine Learning Platform for Building Custom Models' },
    { name: 'Woebot', url: 'https://woebot.io/', purpose: 'AI-Powered Chatbot for Mental Health Support and Therapy' },
    { name: 'Aiva', url: 'https://www.aiva.ai/', purpose: 'AI Music Composition Software for Film and Video Production' },
    { name: 'DAGsHub', url: 'https://dagshub.com/', purpose: 'Collaborative Platform for Machine Learning Projects and Model Development' },
    { name: 'TensorFlow', url: 'https://www.tensorflow.org/', purpose: 'Open-source Machine Learning Framework by Google' },
    { name: 'PyTorch', url: 'https://pytorch.org/', purpose: 'Open-source Machine Learning Library by Facebook AI Research (FAIR)' },
    // Additional tools...
    { name: 'Keras', url: 'https://keras.io/', purpose: 'High-level Neural Networks API, now integrated with TensorFlow' },
    { name: 'scikit-learn', url: 'https://scikit-learn.org/', purpose: 'Simple and efficient tools for data mining and data analysis in Python' },
    { name: 'NumPy', url: 'https://numpy.org/', purpose: 'Fundamental package for scientific computing with Python' },
    { name: 'Pandas', url: 'https://pandas.pydata.org/', purpose: 'Data manipulation and analysis library for Python' },
    { name: 'Matplotlib', url: 'https://matplotlib.org/', purpose: 'Comprehensive library for creating static, animated, and interactive visualizations in Python' },
    { name: 'NLTK', url: 'https://www.nltk.org/', purpose: 'Natural Language Toolkit for building Python programs to work with human language data' },
    { name: 'Gensim', url: 'https://radimrehurek.com/gensim/', purpose: 'Topic modeling and document similarity analysis toolkit in Python' },
    { name: 'AllenNLP', url: 'https://allennlp.org/', purpose: 'Deep learning library built on PyTorch for natural language processing tasks' },
    { name: 'spaCy', url: 'https://spacy.io/', purpose: 'Industrial-strength Natural Language Processing library in Python' },
    { name: 'fast.ai', url: 'https://www.fast.ai/', purpose: 'High-level deep learning library based on PyTorch' },
    { name: 'XGBoost', url: 'https://xgboost.ai/', purpose: 'Scalable and efficient gradient boosting library for machine learning' },
    { name: 'LightGBM', url: 'https://lightgbm.readthedocs.io/', purpose: 'Gradient boosting framework that uses tree-based learning algorithms' },
    { name: 'CatBoost', url: 'https://catboost.ai/', purpose: 'Gradient boosting library with categorical features support' },
    { name: 'Hugging Face Transformers', url: 'https://huggingface.co/transformers/', purpose: 'State-of-the-art natural language processing models and pipelines' },
    { name: 'Streamlit', url: 'https://streamlit.io/', purpose: 'Open-source app framework for machine learning and data science projects' },
    { name: 'Plotly', url: 'https://plotly.com/', purpose: 'Interactive graphing library for Python' },
    { name: 'DVC', url: 'https://dvc.org/', purpose: 'Data version control system for machine learning projects' },
    { name: 'AutoML', url: 'https://en.wikipedia.org/wiki/Automated_machine_learning', purpose: 'Automated machine learning tools for model selection, hyperparameter tuning, and feature engineering' },
    { name: 'Apache Spark MLlib', url: 'https://spark.apache.org/mllib/', purpose: 'Apache Spark’s scalable machine learning library' },
    { name: 'Apache MXNet', url: 'https://mxnet.apache.org/', purpose: 'Flexible and efficient deep learning framework' },
    { name: 'Caffe', url: 'http://caffe.berkeleyvision.org/', purpose: 'Deep learning framework developed by the Berkeley Vision and Learning Center' },
    { name: 'CNTK', url: 'https://cntk.ai/', purpose: 'Microsoft Cognitive Toolkit for deep learning algorithms' },
    { name: 'DVC', url: 'https://dvc.org/', purpose: 'Open-source version control system for machine learning projects' },
    { name: 'MLflow', url: 'https://mlflow.org/', purpose: 'Open-source platform for managing the end-to-end machine learning lifecycle' },
    { name: 'Kubeflow', url: 'https://kubeflow.org/', purpose: 'Machine learning toolkit for Kubernetes' },
    { name: 'DataRobot', url: 'https://www.datarobot.com/', purpose: 'Automated machine learning platform' },
    { name: 'BigDL', url: 'https://bigdl-project.github.io/', purpose: 'Distributed deep learning library for Apache Spark' },
    { name: 'H2O.ai', url: 'https://www.h2o.ai/', purpose: 'Open-source machine learning platform' },

];


  return (
    <div className="boxcontainer">
      <ul className="listAll">
        <div style={{width:"40%"}}>
        <h2 className='heading'>List of AI</h2>
        <hr style={{color:"rgb(0, 255, 0)", marginTop:"3px"}}/>
        </div>
        {aiTools.map(website => (
          <li key={website.name} className="all-items">
            <span className="website-name">{website.name} - </span>
            <a href={website.url} className="website-link" target="_blank" rel="noopener noreferrer">
              {website.purpose}
            </a>
            <span className="verified">Verified</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Aitools;
