import React from "react";

const entertainment = () => {
  const entertainmentWebsites = [
    {
      name: "Netflix",
      url: "https://www.netflix.com",
      purpose: "Streaming Platform for Movies and TV Shows",
    },
    {
      name: "YouTube",
      url: "https://www.youtube.com",
      purpose: "Video Sharing and Streaming Platform",
    },
    {
      name: "Spotify",
      url: "https://www.spotify.com",
      purpose: "Music and Podcast Streaming Service",
    },
    {
      name: "Amazon Prime Video",
      url: "https://www.primevideo.com",
      purpose: "Streaming Platform for Movies and TV Shows",
    },
    {
      name: "Disney+",
      url: "https://www.disneyplus.com",
      purpose:
        "Streaming Platform for Disney, Pixar, Marvel, Star Wars, and National Geographic content",
    },
    {
      name: "HBO Max",
      url: "https://www.hbomax.com",
      purpose:
        "Streaming Platform for HBO, Warner Bros., DC, and Cartoon Network content",
    },
    {
      name: "Apple Music",
      url: "https://music.apple.com",
      purpose: "Music Streaming Service by Apple",
    },
    {
      name: "Twitch",
      url: "https://www.twitch.tv",
      purpose: "Live Streaming Platform for Gamers and Creators",
    },
    {
      name: "Hulu",
      url: "https://www.hulu.com",
      purpose: "Streaming Platform for Movies and TV Shows",
    },
    {
      name: "IMDb",
      url: "https://www.imdb.com",
      purpose: "Database of Movies, TV Shows, and Celebrities",
    },
    {
      name: "TikTok",
      url: "https://www.tiktok.com",
      purpose: "Short-form Video Sharing Platform",
    },
    {
      name: "SoundCloud",
      url: "https://soundcloud.com",
      purpose: "Music Streaming and Sharing Platform",
    },
    {
      name: "Crunchyroll",
      url: "https://www.crunchyroll.com",
      purpose: "Streaming Platform for Anime and Manga",
    },
    {
      name: "Vimeo",
      url: "https://vimeo.com",
      purpose: "Video Sharing Platform for Creatives",
    },
    {
      name: "PlayStation Store",
      url: "https://store.playstation.com",
      purpose:
        "Digital Distribution Platform for PlayStation games, movies, and TV shows",
    },
  ];

  return (
    <div className="boxcontainer">
      <ul className="listAll">
        <div style={{ width: "40%" }}>
          <h2 className="heading">List of Entertainment</h2>
          <hr style={{ color: "rgb(0, 255, 0)", marginTop: "3px" }} />
        </div>
        {entertainmentWebsites.map((website) => (
          <li key={website.name} className="all-items">
            <span className="website-name">{website.name} - </span>
            <a
              href={website.url}
              className="website-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              {website.purpose}
            </a>
            <span className="verified">Verified</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default entertainment;
