import React from 'react'

const news = () => {
  const topNewsWebsites = [
    { name: 'CNN', url: 'https://www.cnn.com', purpose: 'International news organization providing news coverage and analysis' },
    { name: 'BBC News', url: 'https://www.bbc.com/news', purpose: 'British public service broadcaster providing news and analysis' },
    { name: 'The New York Times', url: 'https://www.nytimes.com', purpose: 'American newspaper known for its comprehensive coverage of news, culture, and opinion' },
    { name: 'The Guardian', url: 'https://www.theguardian.com', purpose: 'British newspaper providing in-depth coverage of international news, politics, and more' },
    { name: 'Al Jazeera', url: 'https://www.aljazeera.com', purpose: 'Qatari news organization providing global news and current affairs coverage' },
    { name: 'Reuters', url: 'https://www.reuters.com', purpose: 'International news organization providing unbiased news and information' },
    { name: 'Associated Press', url: 'https://apnews.com', purpose: 'American non-profit news agency providing breaking news and investigative reporting' },
    { name: 'CNN International', url: 'https://edition.cnn.com', purpose: 'International version of CNN providing global news coverage' },
    { name: 'NBC News', url: 'https://www.nbcnews.com', purpose: 'American news organization offering breaking news and feature stories' },
    { name: 'Fox News', url: 'https://www.foxnews.com', purpose: 'American news channel providing conservative news and opinion' },
    { name: 'ABC News', url: 'https://abcnews.go.com', purpose: 'American news organization offering breaking news and analysis' },
    { name: 'CBS News', url: 'https://www.cbsnews.com', purpose: 'American news organization providing breaking news and investigative reporting' },
    { name: 'USA Today', url: 'https://www.usatoday.com', purpose: 'American newspaper offering breaking news, analysis, and opinion' },
    { name: 'The Washington Post', url: 'https://www.washingtonpost.com', purpose: 'American newspaper providing coverage of politics, opinion, and national news' },
    { name: 'NPR (National Public Radio)', url: 'https://www.npr.org', purpose: 'American public radio broadcaster offering news, analysis, and cultural programming' },
    { name: 'BBC World Service', url: 'https://www.bbc.co.uk/worldserviceradio', purpose: 'International radio broadcaster offering news and analysis in multiple languages' },
    { name: 'The Wall Street Journal', url: 'https://www.wsj.com', purpose: 'American business-focused newspaper offering financial news and analysis' },
    { name: 'The Times of India', url: 'https://timesofindia.indiatimes.com', purpose: 'Indian newspaper providing news coverage from India and around the world' },
    { name: 'The Independent', url: 'https://www.independent.co.uk', purpose: 'British online newspaper offering news, analysis, and opinion' },
    { name: 'The Telegraph', url: 'https://www.telegraph.co.uk', purpose: 'British newspaper providing news, analysis, and features' }
  ];
  
  return (
    <div className="boxcontainer">
      <ul className="listAll">
      <div style={{ width: "40%" }}>
          <h2 className="heading">List of News</h2>
          <hr style={{ color: "rgb(0, 255, 0)", marginTop: "3px" }} />
        </div>
        {topNewsWebsites.map(website => (
          <li key={website.name} className="all-items">
            <span className="website-name">{website.name} - </span>
            <a href={website.url} className="website-link" target="_blank" rel="noopener noreferrer">
              {website.purpose}
            </a>
            <span className="verified">Verified</span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default news
