import React from "react";

const ContactUs = () => {
  return (
    <div className="container-form">
      <div className="form-container">
        <div style={{ width: "40%" }}>
          <h2 className="heading">EMail us</h2>
          <hr style={{ color: "rgb(0, 255, 0)", marginTop: "3px" }} />
        </div>
        {/* <form action="#" method="post">
          <label htmlFor="name">Name:</label>
          <input type="text" id="name" name="name" required />

          <label htmlFor="email">Email:</label>
          <input type="email" id="email" name="email" required />

          <label htmlFor="message">Message:</label>
          <textarea id="message" name="message" rows="5" required></textarea>

          <input type="submit" value="Send" />
        </form> */}

        <h1 style={{color:"white" , textAlign:"center", fontSize:"20px" ,fontFamily:"poppins"}}>contact@linksforums.net</h1>
      </div>
      <hr style={{ color: "rgb(0, 255, 0)", marginTop: "30px" }} />
    </div>
  );
};

export default ContactUs;
